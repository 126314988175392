/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
import { TextInput } from 'components/form';
import { LanguageDropdown } from 'components/language-dropdown';
import { Logo } from 'components/logo';
import { AlphaNowLogo } from 'components/logo/alphanow-logo';
import { ANDROID_APP_URL, IOS_APP_URL } from 'consts';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { url } from 'util/url';

export const Home: React.FC = (props) => {
  const router = useRouter();
  const [state, setState] = useState({ guestCourseId: '' });

  const form: React.FormHTMLAttributes<HTMLFormElement> = {
    name: 'Guest Login Redirect',
    method: 'GET',
    onSubmit: (event) => {
      if (props.hasOwnProperty('onSubmit')) {
        (props as any).onSubmit();
        return;
      }
      event.preventDefault();
      router.push(`login/${state.guestCourseId}`);
    },
  };

  /**
   * Change handler for user input into the form field. Sets the input field state to make it controlled.
   * @param {React.BaseSyntheticEvent} event The Change event
   */
  const handleChange = useCallback((event) => {
    if (event.target.name === 'courseID') {
      setState((state) => ({ ...state, guestCourseId: event.target.value }));
    }
  }, []);

  return (
    <>
      <Head>
        <title>{i18n('text.alpha_now', 'AlphaNow')}</title>
      </Head>
      <div className="landing-page-wrapper">
        <div className="header">
          <LanguageDropdown />
          <Logo />
        </div>
        <div className="banner">
          <div className="container">
            <div className="landing-page-head intro">
              <div className="landing-page-title">
                {/* <Logo size='100' /> */}
                <h1 data-testid="Page_Landing_h1_Join_your_Alpha">
                  {i18n('text.join_your_alpha', 'Join your Alpha')}
                </h1>
              </div>
              <h4>
                {i18n(
                  'hint.a_tailored_experience',
                  'AlphaNow is a tailored Alpha experience available via your web browser or the mobile app'
                )}
              </h4>
              <div className="guest-button">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <form {...form}>
                  <TextInput
                    data-testid="Page_Landing_input_course_id"
                    formName={form.name ?? ''}
                    name="courseID"
                    placeholder={i18n('text.enter_alpha_id', 'Enter Alpha ID')}
                    type="text"
                    value={state.guestCourseId}
                    onChange={handleChange}
                    required
                    requiredErrorMessage={i18n(
                      'error.required_field',
                      'Required field'
                    )}
                  />
                  <div className="actions">
                    <button
                      data-testid="Page_Landing_button_join"
                      className="btn"
                    >
                      {i18n('text.join', 'Join')}
                    </button>
                  </div>
                </form>
              </div>
              <div className="admin-button">
                <button
                  data-testid="Page_Landing_button_admin_login"
                  className="btn"
                  onClick={() => router.push(url('admin.login'))}
                >
                  {i18n('text.go_to_admin_login', 'Go to Admin login')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="landing-logo-container">
              <AlphaNowLogo size={500} />
            </div>
            <div className="secondary-info">
              <div className="download-mobile-app-box">
                <div className="call-to-action">
                  <img
                    className="mobile-screenshot"
                    src="/images/mobile-framed_sml.png"
                    alt="Mobile app screenshot"
                  />
                  <div>
                    <h4>
                      {i18n('text.get_the_mobile_app', 'Get the mobile app')}
                    </h4>
                    <p>
                      {i18n(
                        'hint.mobile_apps_invite',
                        'Guests can also join AlphaNow by downloading the mobile app. The data modes allows them to have flexibility in their data consumption'
                      )}
                    </p>
                  </div>
                </div>
                <div className="download-buttons">
                  <a href={IOS_APP_URL} target="_blank" rel="noreferrer">
                    <img src="/images/icons/app-store-ios.svg" />
                  </a>
                  <a href={ANDROID_APP_URL} target="_blank" rel="noreferrer">
                    <img src="/images/icons/app-store-android.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section dark">
          <div className="container">
            <h2>{i18n('text.about_alpha', 'About Alpha')}</h2>
            <h3>
              {i18n(
                'text.curious_to_learn_more',
                'Curious to learn more about Alpha?'
              )}
            </h3>
            <div className="columns">
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.alpha.org/home/"
              >
                {i18n(
                  'hint.run_alpha_info',
                  'Run Alpha: To learn more about Alpha and how to get started running Alpha, click here.'
                )}
              </a>
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.alpha.org/try-alpha/"
              >
                {i18n(
                  'hint.try_alpha_info',
                  'Try Alpha: Alpha is a multi-week course that creates a space where people come and discuss life’s big questions. Interested in trying Alpha? Find an Alpha near you here.'
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
