import AlphaNowLogoSvg from 'public/images/alphanow-logo.svg';
import React from 'react';

interface AlphaNowLogoProps {
  size?: number
}

export const AlphaNowLogo: React.FC<AlphaNowLogoProps> = ({ size = 100 }) => {
  return (
    <div className="alphanow-logo" style={{ fontSize: `${size}%` }}>
      <AlphaNowLogoSvg />
    </div>
  );
};
